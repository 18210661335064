import React, { createContext, useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import {
  IAMSClientMetaData,
  IPerson,
  getDeviceFingerprint,
} from '@datapeace/1up-frontend-shared-api';
import * as Sentry from '@sentry/react';
import {
  IBlobWithDataURL,
  getDeviceGeolocation,
  successBeep,
  errorBeep,
  useIsMounted,
  useRefValue,
} from '@datapeace/1up-frontend-web-utils';
import { ROUTES } from '@datapeace/ams-web-components';
import {
  addPunch,
  waitForTemperatureRecord,
  searchByFace,
} from '@datapeace/ams-web-utils';
import {
  Settings,
  StoredSettings,
  useAppSettings,
  useConfig,
} from '@datapeace/ams-web-hooks';

export interface IFaceData {
  dataUrl: string;
  imageUrl: string;
}
interface GeolocationCoordinates {
  latitude: number;
  longitude: number;
  altitude: number | null;
  accuracy: number;
  altitudeAccuracy: number | null;
}

interface ProcessData {
  faceData: IFaceData | null;
  personData: IPerson | null;
  requiredFields: { isBodyTempRequired: boolean } | null;
  temperatureData: { bodyTemp: string; bodyTempUnit: 'C' | 'F' } | null;
  isGeolocationMandatory: boolean;
}

const initialState: ProcessData = {
  faceData: null,
  personData: null,
  requiredFields: null,
  temperatureData: null,
  isGeolocationMandatory: true,
};

const ProcessDataContext = createContext<{
  processData: ProcessData;
  setProcessData: React.Dispatch<React.SetStateAction<ProcessData>>;
  settings: Settings;
  setSettings: (settings: StoredSettings) => void;
  updateSettings: (settings: Partial<StoredSettings>) => void;
} | null>(null);

const getNextRoute = (
  currentRoute: ValueOf<typeof ROUTES>,
  state: Pick<ProcessData, 'requiredFields'>
): ValueOf<typeof ROUTES> => {
  const routesOrder = [
    { key: ROUTES.WELCOME, isShown: true },
    { key: ROUTES.FACE_CAPTURE, isShown: true },
    {
      key: ROUTES.TEMPERATURE,
      isShown: !!state.requiredFields?.isBodyTempRequired,
    },
    { key: ROUTES.CONFIRM, isShown: true },
  ];

  const currentRouteIndex = routesOrder.findIndex(
    (route) => route.key === currentRoute
  );

  const nextRoute = routesOrder[currentRouteIndex + 1];

  if (!nextRoute) {
    console.warn('No next route found! Returning home route');
    return ROUTES.WELCOME;
  }

  if (!nextRoute.isShown) {
    // this will allow finding the next route with `isShown` set to true
    return getNextRoute(nextRoute.key, state);
  }

  return nextRoute.key;
};

export const useProcessDataContext = () => {
  const processDataContext = useContext(ProcessDataContext);

  if (!processDataContext) {
    throw new Error(
      "ProcessDataContext should be consumed inside it's Provider"
    );
  }

  const { processData, setProcessData, settings, setSettings, updateSettings } =
    processDataContext;
  const processDataRef = useRefValue(processData);
  const config = useConfig();

  const isMountedRef = useIsMounted();

  const navigate = useNavigate();

  const navigateIfMounted = useCallback(
    (path: ValueOf<typeof ROUTES>) => {
      if (isMountedRef.current) navigate(path);
    },
    [isMountedRef, navigate]
  );

  React.useEffect(() => {
    Sentry.configureScope(async (scope) => {
      scope.setUser({
        ...scope.getUser(),
        email: config.email,
        orgId: config.currentOrganization && config.currentOrganization.id,
        orgName: config.currentOrganization && config.currentOrganization.name,
        spaceId: config.currentSpace && config.currentSpace.id,
        spaceName: config.currentSpace && config.currentSpace.name,
      });
    });
  }, [config.currentOrganization, config.currentSpace, config.email]);

  const currentRoute = useLocation().pathname as ValueOf<typeof ROUTES>;
  const handleNavigateToHome = useCallback(
    () => navigateIfMounted(ROUTES.HOME),
    [navigateIfMounted]
  );
  const handleNavigateToSettings = useCallback(
    () => navigateIfMounted(ROUTES.SETTING),
    [navigateIfMounted]
  );
  const navigateToNextRoute = useCallback(
    () => navigateIfMounted(getNextRoute(currentRoute, processDataRef.current)),
    [currentRoute, navigateIfMounted, processDataRef]
  );

  const updateProcessData = useCallback(
    (update: Partial<ProcessData>) => {
      setProcessData((state) => ({ ...state, ...update }));
    },
    [setProcessData]
  );

  const handleResetProcess = useCallback(
    async () => setProcessData(initialState),
    [setProcessData]
  );

  const handleStartProcess = navigateToNextRoute;

  const handleFaceCaptureSubmit = useCallback(
    async (faceData: IBlobWithDataURL) => {
      try {
        if (!config.currentSpace?.id)
          throw new Error('space not found during face capture');

        const {
          fileUrl,
          person,
          punchAllowed,
          punchRequiredFields,
          punchDeniedReason,
          isPunchGelocationMandatory,
        } = await searchByFace(
          config.currentSpace?.id,
          { faceImage: faceData, type: 'web' },
          settings.amsMode
        );
        if (!punchAllowed) {
          throw new Error(
            punchDeniedReason ||
              'Person not authorized to do punch! Please contact admin.'
          );
        }

        if (!person?.id)
          throw new Error('Person is not registered! Please contact admin.');

        updateProcessData({
          faceData: { dataUrl: faceData.dataURL, imageUrl: fileUrl },
          personData: person,
          requiredFields: punchRequiredFields,
          isGeolocationMandatory: isPunchGelocationMandatory !== false,
        });

        navigateToNextRoute();
      } catch (err) {
        if (isMountedRef.current) {
          if (settings.isSoundEnabled) errorBeep(config.punchFailureAudioData);
        }
        throw err;
      }
    },
    [
      config.currentSpace,
      config.punchFailureAudioData,
      navigateToNextRoute,
      updateProcessData,
      settings.isSoundEnabled,
      settings.amsMode,
      isMountedRef,
    ]
  );

  const handleTemperatureSubmit = navigateToNextRoute;

  const handleRecordTemperature = useCallback(async () => {
    try {
      if (!settings.temperatureDeviceId) {
        throw new Error(
          'Temperature device not found. Please select a temperature device from settings.'
        );
      }
      const temperatureData = await waitForTemperatureRecord(
        settings.temperatureDeviceId,
        settings.temperatureWaitTime
      );
      updateProcessData({ temperatureData });

      if (settings.isAutoSubmitEnabled) {
        handleTemperatureSubmit();
      }
    } catch (err) {
      if (isMountedRef.current) {
        if (settings.isSoundEnabled) errorBeep(config.punchFailureAudioData);
      }
      throw err;
    }
  }, [
    settings.temperatureDeviceId,
    config.punchFailureAudioData,
    settings.isAutoSubmitEnabled,
    settings.temperatureWaitTime,
    settings.isSoundEnabled,
    handleTemperatureSubmit,
    updateProcessData,
    isMountedRef,
  ]);

  const handleConfirmSubmit = useCallback(async () => {
    try {
      if (!config.currentSpace?.id)
        throw new Error('space not found during confirm');
      if (!processData?.personData?.id)
        throw new Error('people not found during confirm');
      if (!processData?.faceData?.imageUrl)
        throw new Error('face image not found during confirm');

      const currentMetaData: IAMSClientMetaData = {
        client: {
          deviceId: '',
          geoCoord: undefined,
        },
      };

      const deviceId = await getDeviceFingerprint();
      currentMetaData.client.deviceId = deviceId;
      let geoCoords: GeolocationCoordinates | undefined;
      try {
        geoCoords = await getDeviceGeolocation({
          enableHighAccuracy: true,
          timeout: 10000,
        });
      } catch (e) {
        console.error(e);
        if (processData.isGeolocationMandatory) {
          Sentry.captureException(e);
          throw Error(
            'Failed to get geolocation. Please ensure geolocation is enabled.'
          );
        }
      }
      if (geoCoords) {
        const { longitude, latitude, accuracy } = geoCoords;
        currentMetaData.client.geoCoord = {
          long: longitude,
          lat: latitude,
          accuracyM: accuracy,
        };
      }

      const punchData = await addPunch(
        config.currentSpace?.id,
        settings.amsMode,
        {
          photoUrl: processData.faceData?.imageUrl,
          punchMetadata: currentMetaData,
          person: processData.personData?.id,
          punchType:
            settings.processType === 'BOTH' ? undefined : settings.processType,
          bodyTemp: processData.temperatureData?.bodyTemp || undefined,
          bodyTempUnit: processData.temperatureData?.bodyTempUnit || undefined,
        }
      );

      // in punch, play sound whether component mounted or not.. because action is complete
      if (settings.isSoundEnabled) successBeep(config.punchSuccessAudioData);

      return punchData;
    } catch (err) {
      if (isMountedRef.current) {
        if (settings.isSoundEnabled) errorBeep(config.punchFailureAudioData);
      }
      throw err;
    }
  }, [
    config.currentSpace,
    config.punchSuccessAudioData,
    config.punchFailureAudioData,
    processData,
    settings.processType,
    settings.isSoundEnabled,
    settings.amsMode,
    isMountedRef,
  ]);

  return {
    ...processData,
    config,
    settings,
    setSettings,
    updateSettings,
    navigateIfMounted,
    handleNavigateToHome,
    handleNavigateToSettings,
    handleResetProcess,
    handleStartProcess,
    handleFaceCaptureSubmit,
    handleRecordTemperature,
    handleTemperatureSubmit,
    handleConfirmSubmit,
  };
};

export const ProcessDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [processData, setProcessData] = useState<ProcessData>(initialState);
  const { email } = useConfig();
  const { settings, setSettings, updateSettings } = useAppSettings(email);

  return (
    <ProcessDataContext.Provider
      value={{
        processData,
        setProcessData,

        settings,
        setSettings,
        updateSettings,
      }}
    >
      {children}
    </ProcessDataContext.Provider>
  );
};
