import { IProfile } from '@datapeace/1up-frontend-shared-api';
import React, { useContext } from 'react';
export const AuthContext = React.createContext<IProfile | null>(null);

export const useAuth = () => {
  const profile = useContext(AuthContext);

  if (!profile) {
    throw new Error("ProfileContext should be consumed inside it's Provider");
  }

  return profile;
};
