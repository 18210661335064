export const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT;
export const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL;
export const ADMIN_APP_PATH = import.meta.env.VITE_APP_ADMIN_APP_PATH;
export const BRAND_NAME = import.meta.env.VITE_APP_BRAND_NAME;
export const ASSET_BASE_PATH = import.meta.env.VITE_APP_ASSET_BASE_PATH;

export const LOGIN_PATH = `${ADMIN_APP_PATH}/signin`;
export const LOGOUT_PATH = `${ADMIN_APP_PATH}/signout`;

export const LOGGED_OUT_EVENT = 'LOGGED_OUT_EVENT';

if (ASSET_BASE_PATH === undefined || ASSET_BASE_PATH === null) {
  throw Error(
    'Please set ASSET_BASE_PATH environment variables correctly! Empty string can also be set.'
  );
}

if (!BRAND_NAME) {
  throw Error(
    'Please set BRAND_NAME environment variables correctly! Empty string cannot be set.'
  );
}
