import { useState, useContext, createContext } from 'react';
import {
  ISpace,
  IOrganizationDetailed,
  setCurrentSpaceInStorage,
  removeCurrentSpaceFromStorage,
  setCurrentOrganizationInStorage,
  removeCurrentOrganizationFromStorage,
} from '@datapeace/1up-frontend-shared-api';
import {
  useGetOrganizations,
  useGetCurrentOrganization,
  useGetCurrentSpace,
  useGetConfig,
  useAuth,
} from '@datapeace/ams-web-hooks';
import { IConfigContext } from '@datapeace/ams-web-components';

const ConfigContext = createContext<IConfigContext | null>(null);

export const useConfig = () => {
  const config: IConfigContext | null = useContext(ConfigContext);
  if (!config) {
    throw new Error("ConfigContext should be consumed inside it's Provider");
  }

  // const mockedConfig: IConfigContext & { isContactLessMode?: boolean } = config;
  // mockedConfig.isContactLessMode = true;

  return config;
};

export function useConfigContainer() {
  const { email } = useAuth();

  const [error, setError] = useState('');

  const [organizations, , isLoadingOrganizations] =
    useGetOrganizations(setError);
  const [currentOrganization, setCurrentOrganization] =
    useGetCurrentOrganization(organizations);
  const [currentSpace, setCurrentSpace] =
    useGetCurrentSpace(currentOrganization);
  const [config, setConfig] = useGetConfig(
    currentOrganization,
    currentSpace,
    setError
  );

  // handle space selection of user
  const handleSelectSpace = (space: ISpace | null) => {
    if (space) {
      setCurrentSpace(space);
      setCurrentSpaceInStorage(email, space.id);
    } else {
      setCurrentSpace(null);
      removeCurrentSpaceFromStorage(email);
    }

    // reset config as it depends on selected space
    setConfig(null);
  };
  const handleClearSpace = () => {
    setError('');
    handleSelectSpace(null);
  };

  // handle organization selection of user
  const handleSelectOrganization = (
    organization: IOrganizationDetailed | null
  ) => {
    if (organization) {
      if (
        !(
          organization.canAccessAms &&
          organization.features.organizationsAmsBasic
        )
      ) {
        setError('Cannot access AMS feature');
        return;
      }
      setCurrentOrganization(organization);
      setCurrentOrganizationInStorage(email, organization.id);
    } else {
      setCurrentOrganization(null);
      removeCurrentOrganizationFromStorage(email);
    }

    // reset space as it depends on selected organization
    handleSelectSpace(null);
  };
  const handleClearOrganization = () => {
    setError('');
    handleSelectOrganization(null);
  };

  return {
    email,
    error,
    config,
    isLoading: isLoadingOrganizations,
    organizations,
    currentOrganization,
    currentSpace,
    handleClearSpace,
    handleClearOrganization,
    Provider: ConfigContext.Provider,
    handleSelectSpace,
    handleSelectOrganization,
  };
}
