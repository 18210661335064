/* eslint-disable @typescript-eslint/no-explicit-any */
import mime from 'mime';
import { instance } from './client';
import {
  IGroup,
  IPaginationParams,
  IPaginationResponse,
  IPerson,
  TEMPEATURE_STATUS,
} from '../typings';
import {
  camelToSnakeCase,
  convertToCamelCase,
  convertToSnakeCase,
  createPath,
} from '../utils';
import {
  apiAmsAttendanceDetailPath,
  apiAmsAttendancePunchPath,
  apiAmsAttendancePunchesPath,
  apiAmsAttendancesPath,
  apiAmsPunchAddPath,
  apiAmsPunchDetailPath,
  apiAmsRegularizeAttendanceData,
  apiAmsSearchByFacePath,
  apiAmsSpaceConfigPath,
  apiOrganizationShiftsPath,
  apiOrganizationWeeklyOffPath,
  apiShiftPath,
  apiSpaceAttendanceMetricsPath,
  apiWeeklyOffDetailPath,
  apiWeeklyOffPath,
} from './constants';
import {
  IAmsConfig,
  IAttendance,
  IAttendanceDetailed,
  IFixedShift,
  IFlexibleShift,
  IPunch,
  IPunchDetailed,
  IWeeklyOff,
} from '../typings/ams';

export type IAMS_MODES = 'NORMAL' | 'AUTO_CAPTURE';

export const addPunch = async (
  spaceId: number,
  amsPunchModeType: IAMS_MODES,
  data: Pick<IPunchDetailed, 'photoUrl' | 'punchMetadata'> & {
    person: number;
    punchType?: IPunchDetailed['punchType'];
    bodyTemp?: string;
    bodyTempUnit?: 'C' | 'F';
  }
) => {
  return (
    await instance.client.post<{ item: IPunchDetailed }>(
      createPath(apiAmsPunchAddPath, {
        spaceId,
      }),
      convertToSnakeCase(data),
      {
        params: {
          ...(amsPunchModeType === 'AUTO_CAPTURE' && {
            ams_mode: amsPunchModeType,
          }),
          // Include ams_mode only when ams_mode is 'autocapture'
        },
      }
    )
  ).item;
};

export const searchByFaceInAms = async (
  spaceId: number,
  faceImageData:
    | { faceImage: Blob; type: 'web' }
    | { faceImageUri: string; type: 'native' },
  amsPunchModeType?: IAMS_MODES
) => {
  const form = new FormData();

  // suppose type is application/png, then fileName will be file.png
  if (faceImageData.type === 'native') {
    const fileName = `file.${
      mime.getType(faceImageData.faceImageUri)?.split('/')?.[1] || 'jpeg'
    }`;
    form.append('file', {
      uri: faceImageData.faceImageUri,
      type: mime.getType(faceImageData.faceImageUri),
      name: fileName,
    } as any);
  } else {
    const fileName = `file.${
      mime.getType(faceImageData.faceImage.type)?.split('/')?.[1] || 'jpeg'
    }`;
    form.append('file', faceImageData.faceImage, fileName);
  }
  return await instance.client.post<{
    person: IPerson | null;
    fileUrl: string;
    punchAllowed: boolean;
    punchDenied: boolean;
    punchDeniedReason: string;
    punchRequiredFields: { isBodyTempRequired: boolean };
    isPunchGelocationMandatory: boolean;
  }>(createPath(apiAmsSearchByFacePath, { spaceId }), form, {
    params: {
      ...(amsPunchModeType === 'AUTO_CAPTURE' && {
        ams_mode: amsPunchModeType,
      }),
      // Include ams_mode only when ams_mode is 'autocapture'
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getAmsSpaceConfig = async (spaceId: number) => {
  const res = await instance.client.get<IAmsConfig>(
    createPath(apiAmsSpaceConfigPath, { spaceId })
  );
  return res;
};

export const getAttendanceDetail: (
  attendanceId: number
) => Promise<IAttendanceDetailed> = async (attendanceId: number) => {
  const res = await instance.client.get<{ item: IAttendanceDetailed }>(
    createPath(apiAmsAttendanceDetailPath, { attendanceId })
  );
  return res.item;
};

export const getPunchDetail: (
  punchId: number
) => Promise<IPunchDetailed> = async (punchId: number) => {
  const res = await instance.client.get<{ item: IPunchDetailed }>(
    createPath(apiAmsPunchDetailPath, { punchId })
  );
  return res.item;
};

export const getShifts = async (
  organizationId: number,
  params?: IPaginationParams
) => {
  return await instance.client.get<
    IPaginationResponse<IFixedShift | IFlexibleShift>
  >(createPath(apiOrganizationShiftsPath, { organizationId }), {
    params: convertToSnakeCase(params),
  });
};

export const getAMSMetrics = async (
  spaceId: number,
  start: string,
  end: string
) =>
  instance.client.get<{
    firstPunchInCount: number;
    lastPunchOutCount: number;
    insidePremiseCount: number;
  }>(createPath(apiSpaceAttendanceMetricsPath, { spaceId }), {
    params: { start: start, end: end },
  });

export async function createShift<
  B extends Omit<IFixedShift | IFlexibleShift, 'id'>
>(
  organizationId: number,
  data: B
): Promise<B extends IFixedShift ? IFixedShift : IFlexibleShift> {
  return await instance.client.post<
    B extends IFixedShift ? IFixedShift : IFlexibleShift
  >(
    createPath(apiOrganizationShiftsPath, { organizationId }),
    convertToSnakeCase(data)
  );
}

export async function updateShift<B extends IFixedShift | IFlexibleShift>(
  shiftId: number,
  data: Partial<B>
): Promise<B extends IFixedShift ? IFixedShift : IFlexibleShift> {
  const res = await instance.client.put<{
    item: B extends IFixedShift ? IFixedShift : IFlexibleShift;
  }>(createPath(apiShiftPath, { shiftId }), convertToSnakeCase(data));
  return res.item;
}

export const deleteShift = async (shiftId: number) =>
  await instance.client.delete(createPath(apiShiftPath, { shiftId }));

export const getShiftDetails = async (shiftId: number) => {
  const res = await instance.client.get<{ item: IFixedShift | IFlexibleShift }>(
    createPath(apiShiftPath, { shiftId })
  );
  return res.item;
};

export const getWeeklyOffList = async (
  organizationId: number,
  params?: IPaginationParams
) => {
  return await instance.client.get<IPaginationResponse<IWeeklyOff>>(
    createPath(apiOrganizationWeeklyOffPath, { organizationId }),
    {
      params: convertToSnakeCase(params),
    }
  );
};

export async function createWeeklyOff(
  organizationId: number,
  data: Partial<IWeeklyOff>
) {
  return await instance.client.post<IWeeklyOff>(
    createPath(apiOrganizationWeeklyOffPath, { organizationId }),
    convertToSnakeCase(data, ['weeklyOff'])
  );
}

export async function getWeeklyOff(weeklyOffId: number) {
  const res = await instance.client.get<null, { item: IWeeklyOff }>(
    createPath(apiWeeklyOffDetailPath, {
      weeklyOffId,
    }),
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      convertResponseToCamelCase: false,
    }
  );
  return convertToCamelCase(res.item, ['weekly_off']) as IWeeklyOff;
}

export async function updateWeeklyOff(
  weeklyOffId: number,
  data: Partial<IWeeklyOff>
) {
  const res = await instance.client.put<{
    item: IWeeklyOff[];
  }>(
    createPath(apiWeeklyOffDetailPath, {
      weeklyOffId,
    }),
    convertToSnakeCase(data, ['weeklyOff'])
  );
  return res.item;
}

export const deleteWeeklyOff = async (weeklyOffId: number) =>
  await instance.client.delete(createPath(apiWeeklyOffPath, { weeklyOffId }));

export type AMS_MODES = 'NORMAL' | 'AUTO_CAPTURE';

export const getPunches = async (
  spaceId: number,
  params?: IPaginationParams & {
    sortField?: string;
    sortOrder?: 'ascend' | 'descend';
    punchedAtGte?: string;
    punchedAtLte?: string;
    punchType?: IPunch['punchType'];
    bodyTempStatus?: TEMPEATURE_STATUS;
  }
) =>
  await instance.client.get<IPaginationResponse<IPunch>>(
    createPath(apiAmsPunchAddPath, { spaceId }),
    {
      params: params && {
        search: params.search,
        page: params.page,
        ordering: params.sortField
          ? `${params.sortOrder === 'descend' ? '-' : ''}${camelToSnakeCase(
              params.sortField
            )}`
          : undefined,
        page_size: params.pageSize,
        punched_at__gte: params.punchedAtGte,
        punched_at__lte: params.punchedAtLte,
        punch_type: params.punchType,
        body_temp_status: params.bodyTempStatus,
      },
    }
  );

export const updatePunch = async (
  punchId: number,
  punchData: { punchType: IPunch['punchType'] }
) => {
  const res = await instance.client.put<{ item: IPunchDetailed }>(
    createPath(apiAmsPunchDetailPath, { punchId }),
    convertToSnakeCase(punchData)
  );
  return res.item;
};

export const deletePunch = async (punchId: number) => {
  await instance.client.delete(createPath(apiAmsPunchDetailPath, { punchId }));
};

export const addPunchInAttendance = async (
  attendanceId: number,
  data: Pick<
    IPunchDetailed,
    'punchMetadata' | 'punchType' | 'photoUrl' | 'punchedAt'
  >
) => {
  return await instance.client.post<{ item: IAttendanceDetailed['punches'] }>(
    createPath(apiAmsAttendancePunchesPath, { attendanceId }),
    convertToSnakeCase(data)
  );
};

export const regularizeAttendanceData = async (
  spaceId: number,
  items: number[]
) => {
  return await instance.client.post<{ items: number[] }>(
    createPath(apiAmsRegularizeAttendanceData, { spaceId }),
    {
      items,
    }
  );
};

export const createAttendance = async (
  spaceId: number,
  data: {
    attendanceDate: string;
    person: number;
    shift: number;
    punches: {
      photoUrl?: string;
      punchedAt: string;
      punchType: IPunchDetailed['punchType'];
    }[];
  }
) => {
  return await instance.client.post<{ item: IAttendanceDetailed }>(
    createPath(apiAmsAttendancesPath, { spaceId }),
    convertToSnakeCase(data)
  );
};

export const removePunchFromAttendance = async (
  attendanceId: number,
  punchId: number
) => {
  await instance.client.delete(
    createPath(apiAmsAttendancePunchPath, { attendanceId, punchId })
  );
};

export const deleteAttendance = async (attendanceId: number) => {
  await instance.client.delete(
    createPath(apiAmsAttendanceDetailPath, { attendanceId })
  );
};

export const getAttendances = async (
  spaceId: number,
  params?: IPaginationParams & {
    sortField?: string;
    sortOrder?: 'ascend' | 'descend';
    /** format: 2020-11-02 (YYYY-MM-DD) */
    attendanceDateStart?: string;
    attendanceDateEnd?: string;
    attendanceStatus?: 'first_punch_in' | 'last_punch_out' | 'inside_premise';
    bodyTempStatus?: TEMPEATURE_STATUS;
    status?: 'ABSENT' | 'PRESENT' | 'HALF_DAY_PRESENT' | 'FULL_DAY_PRESENT';
  }
) => {
  return await instance.client.get<IPaginationResponse<IAttendance>>(
    createPath(apiAmsAttendancesPath, { spaceId }),
    {
      params: params && {
        search: params.search,
        page: params.page,
        ordering: params.sortField
          ? `${params.sortOrder === 'descend' ? '-' : ''}${camelToSnakeCase(
              params.sortField
            )}`
          : undefined,
        page_size: params.pageSize,
        attendance_date__gte: params.attendanceDateStart,
        attendance_date__lte: params.attendanceDateEnd,
        attendance_status: params.attendanceStatus,
        body_temp_status: params.bodyTempStatus,
        status: params.status,
      },
    }
  );
};

export const updateAttendance = async (
  attendanceId: number,
  attendanceData: { notes?: string; shift?: number }
) => {
  const res = await instance.client.put<{
    item: Omit<
      IAttendanceDetailed,
      'status' | 'statusDisplay' | 'workingHrsCalcPolicy'
    >;
  }>(
    createPath(apiAmsAttendanceDetailPath, { attendanceId }),
    convertToSnakeCase(attendanceData)
  );
  return res.item;
};

export const updateAmsSpaceConfig = async (
  spaceId: number,
  data: Partial<
    Omit<IAmsConfig, 'temperatureDevices' | 'shifts' | 'weeklyOffsAssigned'> & {
      temperatureDevices: string[];
      shifts: number[];
      weeklyOffsAssigned: {
        weeklyOff: number;
        personGroups: IGroup[];
      }[];
    }
  >
) => {
  const res = await instance.client.put<IAmsConfig>(
    createPath(apiAmsSpaceConfigPath, { spaceId }),
    convertToSnakeCase(data)
  );
  return res;
};
