import React, { useEffect } from 'react';
import {
  createHistory,
  createMemorySource,
  LocationProvider as ReachLocationProvider,
  Router,
  useLocation,
} from '@reach/router';
import {
  Home,
  Welcome,
  FaceCapture,
  Temperature,
  Confirm,
  Settings,
} from '../../screens';

export const ROUTES = {
  HOME: '/',
  WELCOME: '/welcome',
  FACE_CAPTURE: '/face-capture',
  TEMPERATURE: '/temperature',
  CONFIRM: '/confirm',
  SETTING: '/settings',
} as const;

// initial page will be settings if url path is /settings
const getInitialRoute = () => {
  if (window.location.pathname === ROUTES.SETTING) {
    return ROUTES.SETTING;
  }

  return ROUTES.HOME;
};

const source = createMemorySource(getInitialRoute());
const history = createHistory(source);

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ReachLocationProvider history={history}>{children}</ReachLocationProvider>
  );
};

export function Routes() {
  const currentRoute = useLocation().pathname as ValueOf<typeof ROUTES>;

  useEffect(() => {
    // clear pathname in url when closing settings page
    if (currentRoute !== ROUTES.SETTING) {
      window.history.pushState({}, '', ROUTES.HOME);
      return;
    }

    // set url pathname as /settings (if not already set) when opening settings page
    if (window.location.pathname !== ROUTES.SETTING) {
      window.history.pushState({}, '', ROUTES.SETTING);
    }
  }, [currentRoute]);

  return (
    <Router className="ui-full-size">
      <Home path="/" default />
      <Welcome path={ROUTES.WELCOME} />
      <FaceCapture path={ROUTES.FACE_CAPTURE} />
      <Temperature path={ROUTES.TEMPERATURE} />
      <Confirm path={ROUTES.CONFIRM} />
      <Settings path={ROUTES.SETTING} />
    </Router>
  );
}
