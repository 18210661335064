import { useCallback, useEffect, useState } from 'react';
import { useConfig } from '@datapeace/ams-web-hooks';

const SETTINGS_STORAGE_KEY = '-settings';

export const PROCESS_TYPES = { IN: 'IN', OUT: 'OUT', BOTH: 'BOTH' } as const;
export const AMS_MODES = {
  NORMAL: 'NORMAL',
  AUTO_CAPTURE: 'AUTO_CAPTURE',
} as const;
export const detectionScoreThresholdRange = { min: 10, max: 400 } as const;

export interface StoredSettings {
  amsMode: ValueOf<typeof AMS_MODES>;
  processType: ValueOf<typeof PROCESS_TYPES>;
  minFaceSize: number;
  detectionScoreThreshold: number;
  alertScreenDelay: number;
  cameraFacing: 'user' | 'environment';
  isSoundEnabled: boolean;
  temperatureWaitTime: number;
  detectionPreference: 'quality' | 'performance';
  temperatureDeviceId: number | undefined;
}

export interface Settings extends StoredSettings {
  isAutoSubmitEnabled: boolean;
  detectionDownscaling: 2 | 3;
}

export const defaultSettings: StoredSettings = {
  amsMode: 'NORMAL',
  processType: 'BOTH',
  minFaceSize: 150,
  detectionScoreThreshold: 40,
  alertScreenDelay: 3,
  cameraFacing: 'user',
  isSoundEnabled: true,
  temperatureWaitTime: 15,
  detectionPreference: 'quality',
  temperatureDeviceId: undefined,
};

function getSettingsFromStorage(email: string): StoredSettings {
  const settingsString = localStorage.getItem(email + SETTINGS_STORAGE_KEY);
  const settings = JSON.parse(settingsString || '{}') as StoredSettings | null;

  if (!settings) return defaultSettings;

  // validations
  if (
    settings.detectionScoreThreshold < detectionScoreThresholdRange.min ||
    settings.detectionScoreThreshold > detectionScoreThresholdRange.max
  ) {
    settings.detectionScoreThreshold = defaultSettings.detectionScoreThreshold;
  }

  return { ...defaultSettings, ...settings };
}

function setSettingsInStorage(email: string, settings: StoredSettings) {
  localStorage.setItem(email + SETTINGS_STORAGE_KEY, JSON.stringify(settings));
}

export function useAppSettings(email: string) {
  const [storedSettings, setSettings] = useState<StoredSettings>(() =>
    getSettingsFromStorage(email)
  );
  const { currentOrganization, temperatureDevices } = useConfig();
  const features = currentOrganization?.features;

  const updateSettings = useCallback(
    (updatedSettings: Partial<StoredSettings>) => {
      setSettings((currentSettings) => ({
        ...currentSettings,
        ...updatedSettings,
      }));
    },
    []
  );

  useEffect(() => {
    setSettingsInStorage(email, storedSettings);
  }, [email, storedSettings]);

  useEffect(() => {
    if (temperatureDevices) {
      // Initialize temperature device, if only one available device
      if (!storedSettings.temperatureDeviceId) {
        if (temperatureDevices && temperatureDevices.length === 1) {
          updateSettings({ temperatureDeviceId: temperatureDevices[0].id });
        }
      } else if (
        !temperatureDevices.find(
          (device) => device.id === storedSettings.temperatureDeviceId
        )
      ) {
        // If device in local storage is not available, reset
        updateSettings({ temperatureDeviceId: undefined });
      }
    } else {
      // If no temp devices available, reset
      updateSettings({ temperatureDeviceId: undefined });
    }
  }, [storedSettings.temperatureDeviceId, temperatureDevices, updateSettings]);

  const settings: Settings = {
    ...storedSettings,
    isAutoSubmitEnabled: false,
    detectionDownscaling: 3,
  };

  // check for features/permissions
  if (
    storedSettings.amsMode === 'AUTO_CAPTURE' &&
    !features?.spacesAmsAppAutoCaptureMode
  ) {
    settings.amsMode = 'NORMAL';
  }

  if (storedSettings.isSoundEnabled && !features?.spacesAmsAppPunchSound) {
    settings.isSoundEnabled = false;
  }

  // derived settings
  if (storedSettings.amsMode === 'AUTO_CAPTURE') {
    settings.isAutoSubmitEnabled = true;
  }

  if (settings.detectionPreference === 'performance') {
    settings.detectionDownscaling = 3;
  }

  if (settings.detectionPreference === 'quality') {
    settings.detectionDownscaling = 2;
  }

  return { settings, setSettings, updateSettings };
}
